// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

window.$ = $;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("bootstrap/js/dist/alert.js");
require("bootstrap/dist/js/bootstrap.js");

document.addEventListener("turbolinks:load", () => {
  const body = $("body");
  const myNav = $("#myNav");
  const servicesDropdown = $(".dropdown-menu");

  // Opening mobile menu
  $("#menu-burger").on("click", function() {
    myNav.css("width", "100%");
    body.css("overflow-y", "hidden");
  })

  // Closing mobile menu
  $("#menu-closebtn").on("click", function() {
    myNav.css("width", "0");
    body.css("overflow-y", "auto");
  })

  $("#services-dropdown-menu-link, .dropdown-menu").on("mouseenter", function(e) {
    servicesDropdown.addClass("show");
  })

  $(".dropdown-menu").on("mouseleave", function(e) {
    servicesDropdown.removeClass("show");
  })

  $('form#form-contact').on('ajax:error', function(event, request, settings) {
    //do some stuff on error
    $('#modal-error').modal('show')
   })
   
   $('form#form-contact').on('ajax:success', function(evt, data, status, xhr){
    //do some stuff on success
    $('#form-contact')[0].reset();
    $('#modal-thank-you').modal('show')
   })

   $('form#form-contact').on('ajax:beforeSend', function(evt, data, status, xhr){
    //do some stuff on success
    $('.fa-spinner').removeClass("d-none");
    $("#btn-submit").prop("disabled", true);
   })

   $('form#form-contact').on('ajax:complete', function(evt, data, status, xhr){
    //do some stuff on success
    $('.fa-spinner').addClass("d-none");
    $("#btn-submit").removeAttr("disabled");
   })
});
